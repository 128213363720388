<template>
	<div class="container" v-loading="loading">
		<div class="table">
			<el-form ref="form" :model="form" label-width="200px">
				<el-form-item label="商家名称" required>
					<el-input v-model="form.supplier_name" placeholder="请输入商家名称"></el-input>
				</el-form-item>
				<el-form-item label="商家电话" required>
					<el-input v-model="form.supplier_phone" placeholder="请输入商家电话"></el-input>
				</el-form-item>
				<el-form-item label="商家招牌" required>
					<el-upload class="avatar-uploader" :action="serverUrl" :headers="uploadHeaders"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<img v-if="form.supplier_logo" :src="form.supplier_logo" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="开通安心采" required>
					<el-radio-group v-model="form.is_reassuring">
						<el-radio :label="0">不开通</el-radio>
						<el-radio :label="1">开通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="安心采货款冻结天数" required>
					<el-input v-model="form.reassuring_day" placeholder="请输入成单后允许退款的时间,单位分钟" disabled>
						<i class="el-icon-question el-input__icon" slot="suffix" @click.stop="handleIconClick(3)">
						</i>
					</el-input>
				</el-form-item>
				<el-form-item label="极速退款时间(分钟)" required>
					<el-input v-model="form.speed_refund_min" placeholder="请输入成单后允许退款的时间,单位分钟">
						<template slot="append">分钟</template>
						<i class="el-icon-question el-input__icon" slot="suffix" @click.stop="handleIconClick(1)">
						</i></el-input>
				</el-form-item>
				<el-form-item label="售后申请时间(天)" required>
					<el-input v-model="form.service_day_min" placeholder="请输入核销后允许申请售后的时间,单位天">
						<template slot="append">天</template>
						<i class="el-icon-question el-input__icon" slot="suffix" @click.stop="handleIconClick(2)">
						</i></el-input>
				</el-form-item>
				<el-form-item label="可售库存" required>
					<el-radio-group v-model="form.freestyle_stock">
						<el-radio :label="2">相关单据修改</el-radio>
						<el-radio :label="1">自由修改</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="商品资料成本价" required>
					<el-radio-group v-model="form.freestyle_cost">
						<el-radio :label="2">相关单据修改</el-radio>
						<el-radio :label="1">自由修改</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-tooltip class="item" effect="dark" content="允许负库存销售时，商品可售库存不足仍然可以下单，可能导致可售库存和库存台账数量变为负数"
					placement="top-start">
					<el-form-item label="负库存销售" required>
						<el-radio-group v-model="form.sale_stock_type">
							<el-radio :label="2">不允许</el-radio>
							<el-radio :label="1">允许</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-tooltip>

				<el-form-item label="商品默认到货类型" required>
					<el-radio-group v-model="form.default_goods_arrived">
						<el-radio :label="1">当日达</el-radio>
						<el-radio :label="2">次日达</el-radio>
						<el-radio :label="3">预售</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="预售商品到期" required>
					<el-radio-group v-model="form.auto_update_goods_arrived">
						<el-radio :label="0">自动下架</el-radio>
						<el-radio :label="1">更新成默认到货类型</el-radio>
						<el-radio :label="2">无操作</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="在线支付" required>
					<el-radio-group v-model="form.open_onlinepay">
						<el-radio :label="2">关闭</el-radio>
						<el-radio :label="1">开启</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="到店付款" required>
					<el-radio-group v-model="form.open_offlinepay">
						<el-radio :label="2">关闭</el-radio>
						<el-radio :label="1">开启</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-tooltip class="item" effect="dark" content="选择配货对账结清计收入时,请在订单核销后继续操作配货/配货确认/配货结清"
					placement="top-start">
					<el-form-item label="销售收入统计方式" required>
						<el-radio-group v-model="form.financial_income_type" @change="changeFinancialIncomeType">
							<el-radio :label="1">销售订单核销计收入</el-radio>
							<el-radio :label="2">配货对账单结清计收入</el-radio>

						</el-radio-group>
					</el-form-item>
				</el-tooltip>
				<el-form-item label="合作申请审核" required>
					<el-radio-group v-model="form.accept_apply">
						<el-radio :label="0">人工审核</el-radio>
						<el-radio :label="1">自动审核</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-tooltip class="item" effect="dark" content="开启商品推送时，每次进入商家首页下方将弹出订阅提示，客户订阅后可收到商品的上架和下架通知"
					placement="top-start">
					<el-form-item label="开启商品推送" required>
						<el-radio-group v-model="form.push_new_goods">
							<el-radio :label="0">关闭</el-radio>
							<el-radio :label="1">开启</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="授权后可在销售订单和配货单进行在线叫车" placement="top-start">
					<el-form-item label="货拉拉授权">
						<el-button v-if="!is_huolala" @click.stop="huolalaAuthFn">去授权</el-button>
						<el-button type="warning" v-else @click.stop="huolalaCancelFn">取消授权</el-button>
					</el-form-item>
				</el-tooltip>
			</el-form>
			<span slot="footer" class="dialog-footer" style="margin-left:50%">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</div>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				loading: true,
				tmpTarget: [],
				form: {},
				serverUrl: 'erp/v1/common/upload',
				uploadHeaders: {
					token: window.sessionStorage.getItem('token'),
					sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
						.toString(),
					timestamp: timestamp
				},
				is_huolala: false,
				supplier_info: {}
			}
		},
		mounted() {
			this.getIndex()

		},
		methods: {
			changeFinancialIncomeType() {
				this.$alert("切换销售收入统计，请确保已核销销售订单已完成对账结清，并且未结清配货对账单已结清，以免影响销售收入报表", "警告")
			},
			handleIconClick(type) {
				if (type == 1) {
					this.$alert("允许用户成单后在多少分钟内可自主退款", "极速退款时间(分钟)")
				} else if (type == 2) {
					this.$alert("订单核销后允许用户在多少天内可申请售后", "售后申请时间(天)")
				} else {
					this.$alert("安心采商家订单核销后资金将被冻结一定的天数，同时安心采商家将会获得平台更多权益", "安心采")
				}
			},

			// 获取
			getIndex() {
				this.$http.post(`/erp/v1/home/get_info`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.form = data.info
						this.is_huolala = data.is_huolala
						this.supplier_info = this.form
						this.supplier_info.is_huolala = data.is_huolala
						sessionStorage.setItem('supplier_info', JSON.stringify(this.supplier_info))
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				console.log(this.form);
				let url = '/erp/v1/home/save_info'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.supplier_info = this.form
						this.supplier_info.is_huolala = this.is_huolala
						sessionStorage.setItem('supplier_info', JSON.stringify(this.supplier_info))
						this.$message.success(msg);
						//window.location.reload()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 上传
			handleAvatarSuccess(res, file) {
				this.form.supplier_logo = res.data.path
				this.$forceUpdate();
			},
			huolalaAuthFn() {
				//获取授权回调地址
				let url = "erp/v1/huolala/getAuthUrl"
				this.$http.post(`${url}`, {}).then((res) => {
					const {
						code,
						msg,
						data
					} = res.data
					if (code == 200) {
						window.open(data)
					} else {
						this.$message.error(msg);
					}
				});
			},
			huolalaCancelFn() {
				//获取授权回调地址
				let url = "erp/v1/huolala/cancelAuth"
				this.$http.post(`${url}`, {}).then((res) => {
					const {
						code,
						msg,
						data
					} = res.data
					if (code == 200) {
						this.$message.success("操作成功");
						setTimeout(function() {
							location.reload()
						}, 1000)

					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>

<style>
	.notice {
		background: #fff;
		margin-bottom: 20px;
		padding: 20px;
		border-radius: 10px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>