<template>
	<!-- 订单列表 -->
	<div class="orderList">
		<!-- 导出模板 -->
		<div :id="'export-template'+index" v-for="(item, index) in selectedData" :key="index"
			style="position: fixed;top: -999999px;">
			<div :id="'template-title' + index">
				<div style="line-height: 50px;font-size: 20px;" align=center>
					<strong>
						<font>{{ supplier_info.supplier_name }}销售单</font>
					</strong>
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td style="width:50%">
								<font>下单时间：<span>{{ dateVal[0] + '至' +dateVal[1] }}</span></font>
							</td>
							<td style="width:20%">
								<font>客户：<span>{{ item.contacts }}</span></font>
							</td>
							<td style="width:30%">
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
						</tr>
						<tr>
							<td style="width:50%">
								<font>地址：<span>{{ item.address }}</span></font>
							</td>
							<td style="width:20%">
								<font>电话：<span>{{ item.phone }}</span></font>
							</td>
							<td style="width:30%">
								<font>仓库：{{ item.warehouse_name }}</font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div :id="'template-table' + index">
				<table border=1 cellSpacing=0 cellPadding=1 style="font-size: 16px;border-collapse:collapse;width:90%"
					bordercolor="#333333">
					<thead>
						<tr>
							<td width="35%">
								<div align=center><b>商品名称</b></div>
							</td>
							<td width="25%">
								<div align=center><b>商品卖点</b></div>
							</td>
							<td width="10%">
								<div align=center><b>数量</b></div>
							</td>
							<td width="15%">
								<div align=center><b>单价</b></div>
							</td>
							<td width="15%">
								<div align=center><b>小计</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itemChil, indexChil) in item.order_goods" :key="indexChil">
							<td>{{ itemChil.goods_name }}</td>
							<td align=center>{{ itemChil.goods_intro }}</td>
							<td align=center>{{ itemChil.count }}</td>
							<td align=center>{{ itemChil.price }}</td>
							<td align=center>{{ itemChil.amount }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td>实付：</td>
							<td><b>&nbsp;</b></td>
							<td><b>&nbsp;</b></td>
							<td width="14%" align="right">　</td>
							<td width="19%" tdata="subSum" format="#,##0.00" align="center">
								<font color="#0000FF">###元</font>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">下单仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">订单类型:</div>
					<div class="item-input">
						<el-select v-model="searchForm.order_type" placeholder="请选择">
							<el-option v-for="item in orderTypeOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">打印状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.print" placeholder="请选择">
							<el-option v-for="item in printList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="handleSearch">搜索</el-button>
					<el-button
						@click.stop="handleSearchpageNum = 10, searchForm = {}, dateVal = [getCurrentDate() + ' ' + '00:00:00', getCurrentDate() + ' ' + '23:59:59'] ,handleSearch()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-select v-model="pageType" placeholder="打印纸" @change="selectPageType" style="margin-right: 10px;">
					<el-option v-for="item in pageTypeList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-button @click.stop="handlePrint" :disabled="exportDisabled">批量打印</el-button>
				<el-button type="success" @click.stop="handleExport">导出</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border @select="handleParentSelect"
				@select-all="handleParentSelectAll" @selection-change="handleParentSelectionChange" ref="parentTable"
				:header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.order_goods" :ref="'childTable' + props.row.order_id"
							:header-cell-style="{
								background: '#f5f7fa',
								fontWeight: 'bold',
								color: '#303133'
							}" style="width: 100%;border:1px solid #e6a23c">
							<!-- 				<el-table-column type="selection" width="55"></el-table-column> -->
							<el-table-column prop="goods_name" label="商品名称" align="center">
								<template slot-scope="scope">
									<span
										v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name) >= 0"
										style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
									<span v-else>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="goods_intro" label="商品描述" align="center"></el-table-column>
							<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
							<el-table-column prop="count" label="数量" align="center"></el-table-column>
							<el-table-column prop="cost_price" label="平均成本" align="center"></el-table-column>
							<el-table-column prop="cost_amount" label="成本金额" align="center"></el-table-column>
							<el-table-column prop="price" label="平均单价" align="center"></el-table-column>
							<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
							<el-table-column prop="arrived_date" label="配送日期" align="center">
								<template slot-scope="scope">
									<span style="font-weight:bold">{{ scope.row.arrived_date }}</span>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55">
					<template slot-scope="{ row }">
						<el-checkbox :indeterminate="row.indeterminate" @change="toggleParentSelection(row, $event)"
							v-model="row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="配货仓库" prop="warehouse_name" align="center" width="160"></el-table-column>
				<el-table-column label="团长信息" prop="leader_name" align="center" width="160">
					<template slot-scope="scope">
						{{scope.row.leader_name}}<br>{{scope.row.leader_phone}}
					</template>
				</el-table-column>
				<el-table-column label="联系人信息" prop="contacts" align="center" width="160">
					<template slot-scope="scope">
						{{scope.row.contacts}}<br>{{scope.row.phone}}
					</template>
				</el-table-column>
				<el-table-column label="联系地址" prop="address" align="center"></el-table-column>
				<el-table-column label="商品总额" prop="total_amount" align="center" width="160"></el-table-column>
				<el-table-column label="运费总额" prop="delivery_fee" align="center" width="160"></el-table-column>
				<el-table-column label="订单金额" prop="amount" align="center" width="160"></el-table-column>
				<el-table-column label="支付方式" align="center" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">线上微信</span>
						<span v-if="scope.row.type == 2">线上余额</span>
						<span v-if="scope.row.type == 30">线下现金</span>
						<span v-if="scope.row.type == 31">线下转账</span>
						<span v-if="scope.row.type == 32">线下扫码</span>
						<span v-if="scope.row.type == 39" style="color:#F56C6C;font-weight: 600;">线下其他</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click.stop="handlePrint(scope.row)"
							v-if="scope.row.refund !== 1">打印</el-button>

<!-- 						<el-button type="warning" size="mini" @click.stop="handleCallCar(scope.row)"
							v-if="supplier_info.is_huolala">叫车</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="page" @size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" :current-page="page" :page-sizes="pageSizes"
				layout="total, sizes, prev, pager, next, jumper" :page-size="pageNum" :total="total">
			</el-pagination>
		</div>
		<el-dialog title="叫车" :visible.sync="callCarDialog" :close-on-click-modal="false" v-el-drag-dialog width="80%"
			@close="closeCallCarDialog">
			<el-form ref="form" label-width="80px">
				<el-form-item label="地区">
					<el-select v-model="callCarForm.city_id" placeholder="请选择叫车地区" @change="changeCallCarArea"
						style="width: 100%;">
						<el-option v-for="item in cityList" :key="item.city_id" :label="item.name"
							:value="item.city_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车型">
					<el-select v-model="callCarForm.order_vehicle_id" placeholder="请选择用车车型" style="width: 100%;">
						<el-option v-for="item in carList" :key="item.order_vehicle_id" :label="item.vehicle_name"
							:value="item.order_vehicle_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="装货点">
					<el-input v-model="callCarForm.start_contacts" placeholder="请输入装货点联系人"></el-input>
					<el-input v-model="callCarForm.start_phone" placeholder="请输入装货点联系电话"></el-input>
					<el-input v-model="callCarForm.start_address" placeholder="请输入装货点地址"></el-input>
				</el-form-item>
				<el-form-item label="卸货点">
					<el-input v-model="callCarForm.end_contacts" placeholder="请输入装货点联系人"></el-input>
					<el-input v-model="callCarForm.end_phone" placeholder="请输入装货点联系电话"></el-input>
					<el-input v-model="callCarForm.end_address" placeholder="请输入装货点地址"></el-input>
				</el-form-item>
				
			</el-form>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click.stop="handleCallCarFn"
					v-loading.fullscreen.lock="loading">确认叫车</el-button>
				<el-button type="info" @click.stop="callCarDialog = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				currentDateTime: this.getCurrentDateTime(),
				// 仓库
				warehouseOptions: [],
				// 订单类型
				orderTypeOptions: [{
						id: 1,
						name: '普通'
					},
					{
						id: 2,
						name: '信用付'
					}
				],
				// 打印状态
				printList: [{
						id: 0,
						name: '全部'
					},
					{
						id: 1,
						name: '已打印'
					},
					{
						id: 2,
						name: '未打印'
					}
				],
				printDialog: false,
				pageType: 1,
				pageTypeItem: {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				},
				pageTypeList: [{
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				}, {
					id: 2,
					name: '针式二等分多联纸',
					width: 2410,
					height: 1400
				}, {
					id: 3,
					name: '针式一等分多联纸',
					width: 2410,
					height: 2800
				}, {
					id: 4,
					name: '标准A4纸',
					width: 2100,
					height: 2970
				}],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				pageSizes: [10, 50, 200, 1000],
				// 供应商信息
				supplier_info: {},
				// 列表选择
				selectedData: [],
				exportDisabled: false, //批量打印
				callCarDialog: false,
				callCarForm: {},
				cityList:[],
				carList:[]
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 仓库
			this.getWarehouseList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()

			this.pageType = localStorage.getItem("defaultPageType") ?? '1';
			this.pageType = Number(this.pageType)
			if (localStorage.getItem("defaultPageTypeItem")) {
				this.pageTypeItem = JSON.parse(localStorage.getItem("defaultPageTypeItem"));
			} else {
				this.pageTypeItem = {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				};
			}
		},
		watch: {
			'selectedData': {
				deep: true,
				handler(newVal, oldVal) {
					// 打印
					const exportArr = newVal.every(i => {
						return i.refund !== 1
					})
					if (exportArr) {
						this.exportDisabled = false
					} else {
						this.exportDisabled = true
					}
				}
			}
		},
		methods: {
			handleSearch() {
				if (this.loading) return;
				this.loading = true
				this.page = 1
				this.searchForm.begin = this.dateVal[0]
				this.searchForm.end = this.dateVal[1]
				this.getList()
			},
			selectPageType(value) {
				let that = this
				this.pageTypeList.forEach(item => {
					if (item.id == value) {
						that.pageTypeItem = item
					}
				})
				localStorage.setItem("defaultPageType", value);
				localStorage.setItem("defaultPageTypeItem", JSON.stringify(that.pageTypeItem));
			},
			handleExport() {
				this.$http.post(`erp/v1/order/lst_address`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`/erp/v1/order/lst_address`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.tableData.forEach(item => {
							this.$set(item, 'checked', false)
							this.$set(item, 'indeterminate', false)
						})
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 父表全选
			handleParentSelectAll(selection) {
				for (const item of this.tableData) {
					item.checked = selection.length == 0 ? false : true;
					// 改变子表的勾选状态
					this.$nextTick(() => {
						let childTableRef = this.$refs['childTable' + item.order_id];
						// 子表是否存在
						if (childTableRef) {
							// 切换子表勾选状态
							childTableRef.toggleAllSelection();
						}
					});
				}
			},
			// 父表勾选
			handleParentSelect(selection, row) {
				this.$nextTick(() => {
					let childTableRef = this.$refs['childTable' + row.order_id];
					// 子表是否存在
					if (childTableRef) {
						// 切换子表勾选状态
						childTableRef.toggleAllSelection();
					}
				});
			},
			// 父表checkbox勾选
			toggleParentSelection(row, value) {
				if (value) {
					this.$refs.parentTable.toggleRowSelection(row, true);
				} else {
					this.$refs.parentTable.toggleRowSelection(row, false);
				}
			},
			// 父表勾选变化
			handleParentSelectionChange(selection) {
				this.selectedData = JSON.parse(JSON.stringify(selection));
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint(item) {
				if (item.order_ids) {
					this.selectedData = []
					this.selectedData.push(item)
					this.exportType = 1
				} else {
					this.exportType = 2
					if (this.selectedData.length <= 0) {
						this.$message.error('请选择数据');
						return
					}
				}
				// 通知打印
				let orderArr = []
				this.selectedData.forEach(item => {
					orderArr.push(item.order_ids)
				});
				this.$http.post(`/erp/v1/order/print`, {
					order_id: item.order_ids ? item.order_ids.toString() : orderArr.join(',')
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						// 打印
						setTimeout(() => {
							var LODOP = getLodop();
							var totalPrint = 0
							for (var i = 0; i < this.selectedData.length; i++) {
								totalPrint++
								var parentDiv = document.getElementById('export-template' + i);
								var strStyle =
									"<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse}</style>"

								LODOP.SET_PRINT_PAGESIZE(0, this.pageTypeItem.width, this.pageTypeItem
									.height);
								/* 
																LODOP.ADD_PRINT_TABLE(105, "5%", "90%", "100%",
																	strStyle + parentDiv
																	.querySelector("#template-table" + i).innerHTML);
																LODOP.ADD_PRINT_HTM(2, "5%", "90%", 100, parentDiv
																	.querySelector(
																		"#template-title" + i).innerHTML); */
								LODOP.ADD_PRINT_TABLE(105, 20, "RightMargin:20", "BottomMargin:20",
									strStyle + parentDiv
									.querySelector("#template-table" + i).innerHTML);
								LODOP.ADD_PRINT_HTM(2, 20, "RightMargin:20", "BottomMargin:20", parentDiv
									.querySelector(
										"#template-title" + i).innerHTML);

								LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
								LODOP.SET_PRINT_STYLEA(0, "LinkedItem", -1);
								LODOP.NewPageA();
								if (totalPrint == this.selectedData.length) { // 如果已经是最后一份，则终止此循环
									LODOP.PREVIEW()
								}
							}
							this.getList()
							this.selectedData = []
						}, 500);
					} else {
						this.$message.error(msg);
					}
				});
			},
			//叫车
			handleCallCar(item) {
				this.getCityList()
				this.callCarForm.end_contacts = item.contacts
				this.callCarForm.end_phone = item.phone
				this.callCarForm.end_address = item.address
				this.callCarDialog = true
			},
			closeCallCarDialog() {
				this.callCarDialog = false
			},
			handleCallCarFn(){
				
			},
			getCityList(){
				this.$http.post(`erp/v1/huolala/getCityList`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.cityList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			getCarList(){
				this.$http.post(`erp/v1/huolala/getCityInfo`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.carList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			changeCallCarArea(e){
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.export-template {
		#template-title {
			.title-t {
				text-align: center;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 22px;

			}

			.title-box {
				display: flex;
				flex-wrap: wrap;

				.box-item {
					width: 50%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.item-i {
						width: 60%;

						span {
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>