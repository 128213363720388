<template>
	<div class="dataAnalysis" v-loading="loading">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.start = dateVal[0], searchForm.end = dateVal[1], getSalesData(), getRankList()">搜索</el-button>
					<el-button
						@click.stop="searchForm = {}, page = 1, pageNum = 10, searchForm.start = dateVal[0], searchForm.end = dateVal[1], getSalesData(), getRankList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="box">
			<div class="box-item">
				<div class="item-title">订单数</div>
				<div class="item-num">{{ salesData.orderCount }}</div>
			</div>

			<div class="box-item">
				<div class="item-title">下单人数</div>
				<div class="item-num">{{ salesData.personCount }}</div>
			</div>
			<div class="box-item">
				<div class="item-title">退款数</div>
				<div class="item-num">{{ salesData.returnCount }}</div>
			</div>
			<div class="box-item">
				<div class="item-title">订单商品未配数</div>
				<div class="item-num" style="color:#F56C6C"> {{ salesData.noDeliveryCount }} </div>
			</div>
		</div>
		<div class="box">
			<div class="box-item">
				<div class="item-title">订单金额</div>
				<div class="item-num">{{ salesData.orderTotal }}</div>
			</div>
			<div class="box-item">
				<div class="item-title">客单价</div>
				<div class="item-num">{{ salesData.avgOrderTotal }}</div>
			</div>
			<div class="box-item">
				<div class="item-title">退款金额</div>
				<div class="item-num">{{ salesData.returnTotal }}</div>
			</div>


			<div class="box-item">
				<div class="item-title">配货商品待采待配数</div>
				<div class="item-num" style="color:#E6A23C"> {{ salesData.noConfirmPreCount }} </div>
			</div>

		</div>
		<div class="day-echarts">
			<div ref="daySaleChart" style="width: 100%; height: 400px;"></div>
		</div>
		<div class="echarts">
			<div class="echarts-item">
				<div ref="sale" style="width: 100%; height: 400px;"></div>
			</div>
			<div class="echarts-payType">
				<div ref="pay" style="width: 100%; height: 400px;"></div>
			</div>
			<div class="echarts-rank">
				<div class="rank-title">今日商品销售排行</div>
				<div class="rank-list">
					<el-scrollbar style="height:100%">
						<div class="list-item" v-for="(item, index) in rankList" :key="index">
							<img class="item-img" :src="item.goods_pic" mode="" />
							<div class="item-info">
								<p class="name">{{ item.goods_name }}</p>
								<p class="num">销量 <span>{{ item.total_count }}</span>件</p>
							</div>
						</div>
					</el-scrollbar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					start: '',
					end: '',
				},
				loading: true,
				// 仓库
				warehouseOptions: [],
				salesData: {},
				rankList: []
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.start = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 仓库
			this.getWarehouseList()

			this.getSalesData()
			this.getRankList()
			this.getDaySalesData()
			this.getMonthSale()
		},
		methods: {
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 销售数据
			getSalesData() {
				this.$http.post(`erp/v1/home/index`, {
					start: this.searchForm.start,
					end: this.searchForm.end,
					warehouse_id: this.searchForm.warehouse_id
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.salesData = data
						this.loading = false
						// 未审批的合作申请
						sessionStorage.setItem('customerApply', data.customerApply)
						// 支付方式
						var payType = echarts.init(this.$refs.pay);
						var option = {
							title: {
								text: '今日订单支付方式占比',
								left: 'center'
							},
							tooltip: {
								trigger: 'item'
							},
							series: [{
								type: 'pie',
								radius: '50%',
								data: data.payTypeList,
								emphasis: {
									itemStyle: {
										shadowBlur: 10,
										shadowOffsetX: 0,
										shadowColor: 'rgba(0, 0, 0, 0.5)'
									}
								}
							}]
						};
						payType.setOption(option);
						payType.resize();

						if (data.notice != undefined && data.notice != "") {
							this.$alert(data.notice, '系统通知', {
								dangerouslyUseHTMLString: true
							});
						}
					} else {
						this.$message.error(msg);

					}
				});
			},
			// 近三十日销售数据
			getDaySalesData() {
				this.$http.post(`erp/v1/home/thirty`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						var daySaleChart = echarts.init(this.$refs.daySaleChart);
						var option = {
							// legend: {
							//     data: ['近30日核销曲线'],
							//     top: '10px',
							// },
							// grid: {
							//     top: '10%',
							//     right: '3%',
							//     bottom: '0%',
							//     left: '3%',
							//     containLabel: true,
							// },
							// tooltip: {
							//     trigger: 'axis',
							//     axisPointer: {
							//         type: 'shadow'
							//     }
							// },
							// xAxis: {
							//     type: 'category',
							//     data: data.dayList,
							//     axisLabel: {
							//         show: true,
							//         interval: 0,
							//         rotate: 60,
							//         inside: false,
							//         margin: 6,
							//         formatter: '{value} ',
							//     },
							// },
							// yAxis: {
							//     type: 'value'
							// },
							// series: [
							//     {
							//         name: '近30日核销',
							//         type: 'bar',
							//         stack: 'Ad',
							//         emphasis: {
							//             focus: 'series'
							//         },
							//         data: data.data
							//     },
							//     {
							//         name: '近30日手续费',
							//         type: 'bar',
							//         stack: 'Ad',
							//         emphasis: {
							//             focus: 'series'
							//         },
							//         data: data.rateData
							//     }
							// ]
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								}
							},
							legend: {},
							grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
							},
							xAxis: {
								type: 'category',
								data: data.dayList,
								axisLabel: {
									show: true,
									interval: 0,
									rotate: 60,
									inside: false,
									margin: 6,
									formatter: '{value} ',
								},
							},
							yAxis: [{
								type: 'value'
							}],
							series: [{
									name: '核销金额（含手续费)',
									type: 'bar',
									stack: 'Ad',
									emphasis: {
										focus: 'series'
									},
									data: data.data
									// ,
									// itemStyle: {
									//     // 设置柱状图的颜色
									//     color: 'red'
									// }
								},
								{
									name: '手续费',
									type: 'bar',
									stack: 'Ad',
									emphasis: {
										focus: 'series'
									},
									data: data.rateData
									// ,
									// itemStyle: {
									//     // 设置柱状图的颜色
									//     color: '#000'
									// }
								}
							]
						};
						daySaleChart.setOption(option);
						daySaleChart.resize();
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 商品排行
			getRankList() {
				this.$http.post(`erp/v1/home/sale_rank`, {
					start: this.searchForm.start,
					end: this.searchForm.end,
					warehouse_id: this.searchForm.warehouse_id
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.rankList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 最近12个月销售曲线
			getMonthSale() {
				this.$http.post(`erp/v1/home/month`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						var saleChart = echarts.init(this.$refs.sale);
						var option = {
							legend: {
								data: ['近12个月核销金额'],
								top: '10px',
							},
							grid: {
								top: '10%',
								right: '5%',
								bottom: '0%',
								left: '5%',
								// 包含文本
								containLabel: true,
							},
							xAxis: {
								type: 'category',
								boundaryGap: false,
								data: data.monthList
							},
							yAxis: {
								type: 'value'
							},
							series: [{
								name: '近12个月核销金额',
								type: 'line',
								data: data.data,
								label: {
									show: true,
								},
								lineStyle: {
									color: '#87E2B8'
								},
								itemStyle: {
									color: '#87E2B8' // 这里设置折线点的颜色为红色
								},
							}, ]
						};
						saleChart.setOption(option);
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>

<style lang="scss" scoped>
	.dataAnalysis {
		.box {
			display: flex;
			justify-content: space-between;

			.box-item {
				background: #fff;
				width: 25%;
				border-radius: 5px;
				padding: 10px 10px 20px 10px;

				.item-title {
					font-size: 14px;
					color: #696B6F;
				}

				.item-num {
					font-size: 22px;
					margin-top: 15px;
				}
			}
		}

		.day-echarts {
			margin-top: 20px;
			background: #fff;
		}

		.echarts {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			width: 100%;

			.echarts-item {
				background: #fff;
				border-radius: 5px;
				padding-top: 10px;
				width: 57%;
			}

			.echarts-payType {
				background: #fff;
				border-radius: 5px;
				padding-top: 10px;
				width: 20%;
			}

			.echarts-rank {
				background: #fff;
				border-radius: 5px;
				padding: 10px;
				width: 20%;

				.rank-title {
					text-align: center;
					font-size: 20px;
					margin-bottom: 10px;
				}

				.rank-list {
					overflow-y: auto;
					height: 400px;

					.list-item {
						display: flex;
						margin-bottom: 10px;

						.item-img {
							width: 65px;
							height: 65px;
							border-radius: 5px;
							margin-right: 10px;
						}

						.item-info {
							.name {
								font-size: 16px;
								font-weight: 500;
								margin-bottom: 3px;
							}

							.num {
								font-size: 13px;
								color: #f33e2c;

								span {
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>