<template>
	<!-- 微信客服 -->
	<div class="warehouseData">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus"
					@click.stop="dialogVisible = true, form = {kf_wx:''}">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">请使用客服微信搜索微信小程序“客服小助手“，关注并设置“在线"</span>
			</div>
			<el-table :data="tableData" v-loading="loading">
				<el-table-column label="ID" prop="kf_id" align="center" width="100"></el-table-column>
				<el-table-column label="头像" align="center" width="100">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.kf_headimgurl"
							:preview-src-list="[scope.row.kf_headimgurl]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="昵称" prop="kf_nick" align="center" width="150"></el-table-column>
				<el-table-column label="微信账号" prop="kf_wx" align="center"></el-table-column>
				<el-table-column label="微信OpenId" prop="kf_openid" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="'新增'" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="微信账号" required>
					<el-input v-model="form.kf_wx" placeholder="请输入微信账号"></el-input>
					<el-image style="width: 100px; height: 200px"
						src="http://static-th.howtec.cc/images/weixin_demo.jpeg"
						:preview-src-list="['http://static-th.howtec.cc/images/weixin_demo.jpeg']">
					</el-image>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				plDialogVisible: false,
				form: {
					kf_wx: ''
				},
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/wxkf/lst`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				let url = '/erp/v1/wxkf/add'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},

			// 删除
			removeFn(item) {
				this.$confirm('确定要删除客服【' + item.kf_nick + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/wxkf/delete`, {
						kf_openid: item.kf_openid
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>